<template>
  <div class="primary-propose">
    <div class="primary-propose-title">
      <p>意见反馈</p>
    </div>
    <van-form class="primary-propose-main" @submit="onSubmit">
      <div class="content">
        <div class="box ttype">
          <van-field
            v-model="ttype"
            name="ttype"
            label="所属分类"
            required
            placeholder="点击这里选择所属分类"
            :rules="[{ required: true }]"
            @click="showPopttype"
            @focus="noBomBox"
            readOnly
          />
          <van-popup v-model="ttypeshow" position="bottom">
            <van-picker
              show-toolbar
              title="所属分类"
              :columns="ttypecolumns"
              :default-index="1"
              @cancel="ttypeshow = false"
              @confirm="ttypeConfirm"
            />
          </van-popup>
        </div>
        <div class="box border">
          <p class="box-tit">内容描述</p>
          <van-field
            class="box-text"
            v-model="mess"
            name="message"
            rows="2"
            autosize
            required
            :rules="[{ required: true }]"
            type="textarea"
            maxlength="200"
            placeholder="请填写详细书名以及详细问题"
            show-word-limit
          />
        </div>
        <div class="box border">
          <p class="box-tit">上传图片(限3张)</p>
          <van-uploader
            v-model="imgList"
            max-count="3"
            :before-read="beforeRead"
            :after-read="afterRead"
            :before-delete="beforeDelete"
            preview-image
            accept="jpg,jpeg,png"
          />
        </div>
        <div class="box border">
          <van-field
            class="box-text"
            v-model="phone"
            type="number"
            required
            :rules="[{ required: true }]"
            label="手机号"
            placeholder="请输入手机号"
          />
        </div>
        <div class="box border">
          <van-field
            class="box-text"
            v-model="userName"
            type="text"
            required
            :rules="[{ required: true }]"
            label="用户姓名"
            placeholder="请输入姓名"
          />
        </div>
      </div>
      <van-button
        v-show="btnState"
        class="btn"
        block
        type="primary"
        native-type="submit"
        >提交反馈</van-button
      >
    </van-form>
  </div>
</template>

<script>
import { Dialog, Toast, Notify } from "vant";
import qs from "qs";

export default {
  name: "propose",
  data() {
    return {
      btnState: true,
      //bname: this.$route.query.bname == undefined ? '' : this.$route.query.bname,
      bid: this.$route.query.bid == undefined ? "" : this.$route.query.bid,
      fid: this.$route.query.fid == undefined ? "" : this.$route.query.fid,
      rid: this.$route.query.rid == undefined ? "" : this.$route.query.rid,
      source:
        this.$route.query.source == undefined ? 5 : this.$route.query.source,
      mess: "",
      ttype: "",
      ttypeshow: false,
      ttypecolumns: [
        {
          values: ["资源内容错误", "意见反馈"],
          defaultIndex: 1,
        },
      ],
      imgList: [],
      phone: "",
      userName: "",
    };
  },
  mounted() {},
  methods: {
    beforeRead() {
      return true;
    },
    afterRead(file) {
      let deviceFile = [];
      const formData = new FormData();
      if (Array.isArray(file)) {
        deviceFile = file;
        file[0].status = "uploading";
        file[0].message = "上传中...";
      } else {
        deviceFile.push(file);
        //单个上传
        file.status = "uploading";
        file.message = "上传中...";
      }
      deviceFile.map((item) => {
        formData.append("fileList", item.file);
        formData.append("x-oss-content-type", "image/jpeg");
      });
      this.$api.mary.UploadFile(formData).then((res) => {
        file.status = "done";
        let { tag, data, message } = res;
        if (tag == 1) {
          let url = {
            url: data,
          };
          this.imgList.push(url);
          for (let i = 0; i < this.imgList.length; i++) {
            if (!this.imgList[i].url) {
              this.imgList.splice(i, 1);
            }
          }
          //console.log(this.imgList);
        } else {
          Dialog.alert({
            message: message,
          });
        }
      });
    },
    beforeDelete(v) {
      return true;
    },
    showPopttype() {
      this.ttypeshow = true;
    },
    noBomBox(Event) {
      document.activeElement.blur();
    },
    ttypeConfirm(e) {
      this.ttype = e[0];
      this.ttypeshow = false;
    },
    onSubmit() {
      if (this.ttype == "") {
        Notify({
          message: "选择所属分类",
          duration: 3000,
        });
        return false;
      }
      if (this.mess == "") {
        Notify({
          message: "填写详细说明",
          duration: 3000,
        });
        return false;
      }
      if (this.phone == "") {
        Notify({
          message: "填写手机号",
          duration: 3000,
        });
        return false;
      }
      if (this.userName == "") {
        Notify({
          message: "填写姓名",
          duration: 3000,
        });
        return false;
      }
      let url = "";
      if (this.imgList.length > 0) {
        for (let i = 0; i < this.imgList.length; i++) {
          url += this.imgList[i].url + ",";
        }
      }
      let params = {
        //增加手机号mobile，用户名userName
        userName: this.userName,
        mobile: this.phone,
        type: this.ttype,
        remark: this.mess,
        imgUrl: url,
        bid: this.bid,
        fid: this.fid,
        rid: this.rid,
        source: this.source,
      };
      console.log(params);
      this.$api.mary.SaveFeedback(params).then((res) => {
        let { tag, message } = res;
        if (tag == 1) {
          //操作页面
          Dialog({
            title: "保存成功",
            message: "成功提交反馈，小编收到会第一时间解决，感谢您的支持",
          });
          this.ttype = "";
          this.mess = "";
          this.btnState = false;
          //console.log(res);
        } else {
          Dialog({
            title: "出现错误",
            message: message,
          });
        }
      });
    },
  },
};
</script>

<style scoped></style>
